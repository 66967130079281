.DateRangePicker_picker {
  z-index: 10;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.col-gap-5 {
  col-gap: 0.5rem;
}

.row-gap-5 {
  row-gap: 0.5rem;
}

.icon {
  height: 25px;
  width: 25px;
}

.icon-lg {
  height: 50px;
  width: 50px;
}

.icon-xl {
  height: 100px;
  width: 100px;
}

.icon-xxl {
  height: 200px;
  width: 200px;
}
.switch-slider{
  z-index: 0;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items{
  margin-left: 25px;
}
.sidebar .nav-link.active, .sidebar .nav-link.active .nav-icon {
  color: orange !important;
}
.sidebar .nav-dropdown-toggle::before{
  transform: scaleX(-1);
}